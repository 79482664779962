//initial state
// get browser language
// const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
const state = {
    language: (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage,
};

//getters
const getters = {
    getLanguage(state) {
        let language = state.language;
        if (language.indexOf('-') !== -1) {
            language = language.split('-')[0];
        }
        return language;
    },
};

// mutations
const mutations = {
    setLanguage(state, data) {
        state.language = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
