// initial state
const state = {
    token: null,
    userData: {},
};

// getters
const getters = {
    getToken(state, getters) {
        return state.token;
    },
    getUser(state, getters) {
        return state.userData;
    },
};

// mutations
const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    setUser(state, userData) {
        state.userData = userData;
    },
    removeToken(state) {
        state.token = null;
    },
    resetUser(state) {
        state.userData = {};
        state.token = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
