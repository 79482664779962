import { i18n } from '@/plugins/i18n';
import {
    mdiAccountOutline,
    mdiHomeOutline,
    mdiNoteTextOutline,
    mdiParking,
    mdiBus,
    mdiBusStopUncovered,
    mdiCalendarClock,
} from '@mdi/js';
import store from '@/store';

let menuItems = [
    {
        title: 'Organization',
        icon: mdiHomeOutline,
        to: 'Organization',
    },
    {
        title: 'Transports',
        icon: mdiBus,
        to: 'TransportsList',
    },
    {
        title: 'Routes',
        icon: mdiBusStopUncovered,
        to: 'RoutesList',
    },

    {
        title: 'Schedule',
        icon: mdiCalendarClock,
        to: 'SchedulesList',
    },

    {
        title: 'Parks',
        icon: mdiParking,
        to: 'ParkList',
    },
    {
        title: 'Reports',
        icon: mdiNoteTextOutline,
        to: 'Reports',
    },
    {
        title: 'Users',
        icon: mdiAccountOutline,
        to: 'UserList',
    },
];

/* let userRole = null;
if (store.getters['auth/getUser']) {
    userRole = store.getters['auth/getUser'].role.type;
    console.log('userRole', userRole);
} else {
    userRole = null;
}

if (userRole === 'ioparking') {
    menuItems = menuItems.filter(
        (item) => item.to !== 'TransportsList' && item.to !== 'RoutesList' && item.to !== 'SchedulesList',
    );
} else if (userRole === 'iobus') {
    menuItems = menuItems.filter((item) => item.to !== 'ParkList');
} else {
    menuItems = menuItems;
}
 */
export default menuItems;
