import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import validator from 'validator';

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

extend('postal-code', {
    validate(value) {
        return validator.isPostalCode(value, 'any');
    },
    message: 'This is not a valid postal code',
    //   message: (_, values) => i18n.t('validations.postal-code', values),
});
extend('mobile-phone', {
    validate(value) {
        return validator.isMobilePhone(value);
    },
    message: 'This is not a valid mobile phone number',
    // message: (_, values) => i18n.t('validations.mobile-phone', values),
});
extend('lat-lgn', {
    validate(value) {
        return validator.isLatLong(value);
    },
    message: 'This is not a valid lat and lgn  number',
    // message: (_, values) => i18n.t('validations.mobile-phone', values),
});
