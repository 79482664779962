import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import '@/plugins/vee-validate';

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

import GmapVue from 'gmap-vue';
Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAPS_KEY,
        libraries: 'places',
        installComponents: true,
    },
});
