import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Vue from 'vue';
import Vuex from 'vuex';
import app from './app';

Vue.use(Vuex);
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: process.env.VUE_APP_NAME,
    // modules: ['auth'],
});

import auth from './modules/auth';
import organization from './modules/organization';
import language from './modules/language';
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        appConfig: appConfigStoreModule,
        app,
        auth,
        organization,
        language,
    },
    plugins: [vuexLocal.plugin],
});
