import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

// initial state
const state = {
    organizationData: {},
    query: {
        populate: ['pictures'],
    },
};

// getters
const getters = {
    getOrganization(state, getters) {
        return state.organizationData;
    },
};

// mutations
const mutations = {
    setOrganization(state, organizationData) {
        state.organizationData = organizationData;
    },
};

//ACTION
const actions = {
    fetchOrganization(ctx, id, query = {}) {
        query.populate = ['pictures'];
        return new Promise((resolve, reject) => {
            axios
                .get(`/entities/${id}`, { params: query })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    },
    updateOrganization(ctx, organizationData) {
        return new Promise((resolve, reject) => {
            let _data = _.assign({}, organizationData);
            let id = _data.id;
            delete _data.id;
            axios
                .put(`/entities/${id}`, { data: _data })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    },
};
/* updatePark(ctx, data) {
    let query = qs.stringify({
        populate: ['pictures', 'country', 'district', 'county', 'parish', 'managers'],
    });
    return new Promise((resolve, reject) => {
        let _data = _.assign({}, data);
        let id = _data.id;
        delete _data.id;
        _data.pictures = _.get(_data, 'pictures[0].id') ? [_.get(_data, 'pictures[0].id')] : [];
        axios
            .put(`/parking-lots/${id}?${query}`, { data: _data })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}, */
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
