import messages from '@/plugins/i18n/locales/en';
import messagespt from '@/plugins/i18n/locales/pt';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store';
Vue.use(VueI18n);

let language = store.getters['language/getLanguage'];

export const i18n = new VueI18n({
    locale: language, // set locale
    fallbackLocale: language,
    messages: {
        en: messages,
        pt: messagespt,
    }, // set locale messages
});

const loadedLanguages = [language]; // our default language that is preloaded

function setI18nLanguage(lang) {
    i18n.locale = lang;
    store.commit('language/setLanguage', lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    /* eslint-disable prefer-template */
    return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then((msgs) => {
        i18n.setLocaleMessage(lang, msgs.default);
        loadedLanguages.push(lang);

        return setI18nLanguage(lang);
    });
    /* eslint-enable */
}
