export default {
    Organization: 'Organization',
    Parks: 'Parks',
    Reports: 'Reports',
    Users: 'Users',
    Logout: 'Logout',
    OrganizationData: 'General Information',
    Search: 'Search',
    ParkList: 'Park List',
    CreatePark: 'Add Park',
    Name: 'Name',
    Email: 'E-mail',
    Country: 'Country',
    Role: 'Role',
    District: 'District',
    County: 'County',
    Parish: 'Parish',
    Managers: 'Managers',
    Lotation: 'Lotation',
    OcupationTax: 'Occupation Tax (%)',
    Filters: 'Filters',
    DeleteFiltes: 'Delete Filters',
    RowsPerPage: 'Rows per page',
    ParkProfile: 'Park Profile',
    ParkStructure: 'Park Structure',
    AddPark: 'Add Park',
    Map: 'Map',
    Transports: 'Transports',
    TransportsList: 'Transports List',
    CreateTransport: 'Add Transport',
    Departure: 'Departure',
    Arrival: 'Arrival',
    Time: 'Time',
    Distance: 'Distance',
    Roadway: 'Roadway',
    Railway: 'Railway',
    Subway: 'Subway',
    TransportProfile: 'Transport Profile',
    Type: 'Type',
    TransportRoutes: 'Transport Routes',
    Routes: 'Routes',
    CreateRoute: 'Add Route',
    RouteProfile: 'Route Profile',
    TotalTime: 'Total Time',
    EmailAlreadyExists: 'This email already exists.',
    ManagerTooltip: 'Manager | Managers',
    ParkTooltip: ' Park | Parks',

    //estrutura do parque
    Floors: 'Floors',
    Sections: 'Sections',
    FloorsQuantity: 'Floors Quantity',
    NumberFloors: 'Number of Floors',
    InitialFloor: 'Initial Floor Number',
    SpotsTotal: 'Total Number of Spots',
    AddFloor: 'Add Floor',
    Number: 'Number',
    Floor: 'Floor',
    Spots: 'Spots',
    SectionsNumber: 'Number of Sections',
    AddSection: 'Add Section',
    FloorsNumbers: 'Total Number of Floors:',
    SectionsNumbers: 'Total Number of Sections:',
    Limit: 'Number of spots in the section is greater than the number of spots in the floor',
    Section: 'Section',
    Identifier: 'Identifier',
    AddUser: 'Add User',
    UserProfile: 'User Profile',
    SortBy: 'Sort by',

    General: 'General',
    Individual: 'Individual',
    SpotTypes: 'Spot Types',
    Quantity: 'Quantity',
    NormalSpot: 'Normal Spots',
    PregnantSpot: 'Pregnant Spots',
    DisabledSpot: 'Disabled Spots',
    ParkContent: 'Park Content',
    Monitoring: 'Monitoring',
    SpotNumber: 'Spot Number',
    Sensor: 'Sensor',
    Actions: 'Actions',
    spotQuantityError: 'The number of lots exceeds the number of lots on the floor',
    ParkType: 'Park Type',
    ActivePark: 'Active Park',

    //alerts
    Alerts: {
        Success: 'Success!',
        UpdateSuccessEnt: 'Information updated successfully!',
        Error: 'Error!',
        ErrorMessage: 'An error occurred. Please try again later.',
        DeleteSuccess: 'Park deleted successfully.',
        UpdateSuccess: 'Park updated successfully.',
        AddSuccess: 'Park added successfully.',
        Sure: 'Are you sure?',
        SureDelete: 'Are you sure you want to delete the park',
        SureDeteteParks: 'Are you sure you want to delete the parks',
        DeleteUser: 'Are you sure you want to delete the user',
        SureDeteteUsers: 'Are you sure you want to delete the users',
        Irreversible: 'This action is irreversible.',
        DeleteSuccessUser: 'User deleted successfully.',
        AddUserSuccess: 'User added successfully.',
        UpdateUserSuccess: 'User updated successfully.',
        DeleteUserSuccess: 'User deleted successfully.',
        AddTransportSuccess: 'Transport added successfully.',
        UpdateTransportSuccess: 'Transport updated successfully.',
        DeleteTransportSuccess: 'Transport deleted successfully.',
        DeleteTransport: 'Are you sure you want to delete the transport',
        SureDeleteTransports: 'Are you sure you want to delete the transports',
        SureDeleteFloor: 'Are you sure you want to delete the floor',
        SureDeleteSpot: 'Are you sure you want to delete the lot',
        SureDeleteSection: 'Are you sure you want to delete the section',
        SureDeleteGeneralLot: 'Are you sure you want to delete the',
    },

    //buttons
    Buttons: {
        Save: 'Save',
        Conclude: 'Conclude',
        Cancel: 'Cancel',
        Edit: 'Edit',
        Delete: 'Delete',
        Filter: 'Filter',
        Add: 'Add',
    },

    Form: {
        Name: 'Name',
        Email: 'E-mail',
        Nif: 'VAT Number',
        Phone: 'Phone',
        Schedule: 'Schedule',
        Cost: 'Cost',
        ParkManagers: 'Park Managers',
        Sensors: 'Sensors',
        Address: 'Address',
        PostalCode: 'Postal Code',
        LatLng: 'Latitude, Longitude',
        Gender: 'Gender',
        TransportManagers: 'Transport Managers',
    },
    Language: {
        English: 'English',
        Portuguese: 'Portuguese',
    },
    Login: {
        Login: 'Login',
        Welcome: 'Welcome to ioCity',
        Password: 'Password',
        Forgot: 'Forgot your password?',
    },
};
